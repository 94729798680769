@use "index" as *;

:root {
  color-scheme: dark;
}

@font-face {
  font-family: 'Manrope';
  src: url('assets/fonts/Manrope-VariableFont_wght.ttf') format('ttf');
  src: url('assets/fonts/Manrope-VariableFont_wght.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.wrapper {
  display: flex;
  flex-direction: column;
  padding: 2.5rem;
}

body {
  background: black !important;
}

// **************************** CALENDAR STYLING **************************** //

//BUTTONS
.fc-prev-button {
  margin-right: 0.5rem !important;
}

.fc-next-button,
.fc-prev-button {
  background-color: var(--tui-primary) !important;
  color: var(--tui-primary-text) !important;
  border-radius: 8px !important;
  border: none !important;
}

.fc-next-button:active,
.fc-prev-button:active {
  background: var(--tui-primary-active) !important;
  transition-property: color, background, opacity !important;
  transition-duration: var(--tui-duration, 100ms) !important;
  transition-timing-function: ease-in-out !important;
}

.fc-next-button:hover,
.fc-prev-button:hover,
.fc-button-active:hover {
  background: var(--tui-primary-hover) !important;
  transition-property: color, background, opacity !important;
  transition-duration: var(--tui-duration, 100ms) !important;
  transition-timing-function: ease-in-out !important;
}

.fc-next-button:active,
.fc-prev-button:active,
.fc-button-active:active {
  background: var(--tui-primary-active) !important;
}

.fc-timeGridWeek-button,
.fc-timeGridDay-button {
  padding-inline: 1.25rem !important;
  color: var(--tui-link) !important;
  background-color: var(--tui-secondary) !important;
  border: none !important;
}

.fc-timeGridWeek-button {
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
}

.fc-timeGridDay-button {
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}

.fc-button-active {
  color: var(--tui-primary-text) !important;
  background-color: var(--tui-primary) !important;
}

.fc-button-active:focus,
.fc-button-primary:focus {
  outline: none !important;
  box-shadow: none !important;
}

.fc-event-time,
.fc-event-title {
  padding: 0 1px !important;
  white-space: normal !important;
}

.fc-event-main {
  overflow: hidden !important;
}

// ROWS
.fc-scrollgrid {
  border: none !important;
}

.fc-toolbar h2 {
  display: inline;
  font-size: 0.9rem !important;
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
  font-weight: 400 !important;
}

.fc-toolbar-chunk {
  display: flex;
  align-items: center;
}

.fc-day-today {
  // todays indicator
  background-color: var(--tui-secondary) !important;
  color: var(--tui-link) !important;
}

.fc-timegrid-divider {
  background: var(--tui-primary) !important;
}

tui-table-pagination {
  tui-hosted-dropdown {
    button {
      border: 2px solid var(--tui-primary) !important;
      padding: 0.3rem 1rem !important;
      margin: 0 0.2rem !important;
      border-radius: 1rem !important;
    }
  }
}

// **************************** CALENDAR STYLING **************************** //

// ******************************* TUI STYLING ****************************** //
.error-wrapper {
  // For error messages on the bottom of an input field
  padding-top: 0.1rem;
  height: 1.3rem;
  width: 100%;
}

.t-message-text {
  // For error messages on the bottom of an input field
  margin: 0 !important;
}

// ******************************* TUI STYLING ******************************* //
.page {
  padding: 0 4rem 0 4rem;

  .page-header {
    padding: 4rem 0 3rem 0;
  }
}


.t-strong[_ngcontent-ng-c854953194] {
  color: var(--tui-primary) !important;
}

td {
  border-right: transparent !important;
  border-left: transparent !important;
  background-color: transparent !important;
}

th {
  border-color: transparent !important;
}

.infobit-wrapper {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  gap: 6rem;
}

.card-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0.5rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.island-wrapper {
  padding-inline: 2rem;
  padding-block: 1rem;
  max-width: 24rem;
}

.order-number {
  display: flex;
  align-items: center;
  gap: 1rem;
}

svg {
  height: 100%;
}

[data-status=neutral][_nghost-ng-c783107866] {
  //background-color: var(--tui-neutral-bg-night) !important;
}


