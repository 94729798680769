@import "./colors";

// ******************************* TUI GLOBAL STYLING ****************************** //
.error-wrapper {
  // For error messages on the bottom of an input field
  padding-top: 0.1rem;
  height: 1.3rem;
  width: 100%;
}

.t-message-text {
  // For error messages on the bottom of an input field
  margin: 0 !important;
}


:root {
  // Primary
  --tui-primary: #{$primary};
  --tui-primary-active: #{$primary};
  --tui-primary-hover: #{$primary_hover};

  // Secondary
  --tui-secondary: #{$white_soft}; // $black_light
  --tui-secondary-active: #{$light_grey};
  --tui-secondary-hover: #{$light_grey};

  // error, warn, success colors
  --tui-error-bg: #{$error_bg};
  --tui-error-txt: #{$error_txt};

  --tui-charge-red: #{$charge-red};

  --tui-warn-bg: #{$warn_bg};
  --tui-warn-txt: #{$warn_txt};

  --tui-success-bg: #{$success_bg};
  --tui-success-txt: #{$success_txt};
  --tui-info-bg: #C2D6FF;
  --tui-info-fill: dodgerblue;

  // not used
  --tui-negative: #{$red};
  --tui-error-bg-hover: #{$red_lighter};

  // Link
  --tui-link: #{$primary};
  --tui-link-hover: #{$primary_hover};

  // Status

  // Dropdown & Popup
  --tui-elevation-02: #{$white};
  --tui-elevation-01: #{$white};

  // Text TODO
  --tui-text-01: #{$white};
  --tui-text-02: #{$grey};
  --tui-text-03: #{$dark_grey};

  --tui-text-dark-01: #{$black};
  --tui-text-dark-02: #{$black_light};
  --tui-text-dark-03: #{$dark_grey};

  --tui-selection: #{$primary};
  --tui-heading-font: 'Manrope';
  --tui-text-font: 'Manrope';

  // Base / Background
  --tui-base-01: #{$white};
  --tui-base-02: #{$white_soft};
  --tui-base-03: #{$dark_grey};
  --tui-base-04: #{$grey_accent};

  --tui-autofill: #{$black_light};

  // Base / Background - light
  --tui-base-light-01: #{$white};

  --tui-shadow-modal: none !important;

  --tui-support-05: red;

  // Chart
  --tui-chart-0: #{$primary};
  --tui-chart-1: #{#e03b82}; // $success_bg
}

// ******************************* TUI STYLING ******************************* //
input:-webkit-autofill {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0 50px white inset;
}

.page {
  padding: 0 4rem 0 4rem;

  .page-header {
    padding: 4rem 0 3rem 0;
  }
}

tui-hint {
  background-color: var(--tui-secondary) !important;
  color: var(--tui-text-dark-01) !important;
}

tui-push {
  --tui-link: #{$white_soft};
  --tui-link-hover: #{$white};
}

td {
  color: var(--tui-text-dark-02) !important;
}

th {
  border-color: transparent !important;
  border-bottom: 0.5px solid var(--tui-base-03) !important;
  color: var(--tui-text-dark-03) !important;

  button {
    color: var(--tui-text-dark-03) !important;
  }
}


.infobit-wrapper {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  gap: 6rem;
  width: 100%;
}

.card-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0.5rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.island-wrapper {
  padding-inline: 2rem;
  padding-block: 1rem;
  max-width: 24rem;
}

[_nghost-ng-c267318630]:not([data-mode])._active {
  color: var(--tui-primary) !important;
}

tui-island {
  background-color: var(--tui-base-light-01) !important;
  color: var(--tui-text-dark-02) !important;
}

// dropdown text
[tuiWrapper][data-appearance='textfield'] {
  color: var(--tui-text-dark-02) !important;
  background-color: transparent !important;
}

[tuiWrapper][data-appearance='outline'] {
  color: var(--tui-text-dark-02) !important;
  border-radius: 12px;
}

tui-tabs {
  box-shadow: 0 1px var(--tui-text-03) !important;

  display: flex;
  gap: 1.25rem;

  a {
    font-size: 1.15rem !important;
    color: var(--tui-text-01) !important;
  }
}

[tuiWrapper][data-appearance='primary'] {
  color: var(--tui-base-01) !important;
}


[tuiWrapper][data-appearance='secondary-destructive'] {
  background: var(--tui-secondary) !important;
}

[tuiWrapper][data-appearance='icon'][data-appearance='icon'] {
  color: var(--tui-text-dark-02);
}

tui-radio-labeled {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
  margin-bottom: 0 !important;
}

tui-input-number {
  margin: 0 !important;
  padding-top: 0 !important;
  justify-self: flex-start !important;
}

[_nghost-ng-c267318630]:not([data-mode])._active.tab-container {
  color: var(--tui-text-dark-02) !important;

  span {
    box-shadow: none !important;
    border-bottom: none !important;
  }
}

.t-content[_ngcontent-ng-c4160801985] {
  padding: 2rem !important;
  background-color: white !important;
  color: black !important;
}

[_nghost-ng-c169493491], .t-content[_ngcontent-ng-c2877806562] {
  color: var(--tui-text-dark-01) !important;
}

tui-primitive-calendar [data-type="weekday"] {
  color: var(--tui-text-dark-02) !important;
}

[data-size=s][_nghost-ng-c4160801985] .t-heading[_ngcontent-ng-c4160801985] {
  text-align: center !important;
}

tui-input-files {
  color: #fff !important;

  div {
    color: var(--tui-text-dark-03);
  }

  .hint, input {
    opacity: 0 !important;
  }

  /* Stil für den Datei-Input hinzufügen */
}

tui-files {
  tui-svg {
    color: var(--tui-text-dark-03);
  }
}

// customized delete button for dynamic forms
#delete-button {
  width: 2rem;

  .t-wrapper {
    padding-left: 1.25rem !important;
    padding-right: 0 !important;
  }

  tui-svg {
    color: var(--tui-negative)
  }
}

// Snackbar / Push text color
.t-content[_ngcontent-ng-c219241606], .t-content[_ngcontent-ng-c3402921129] {
  color: var(--tui-success-txt) !important;
}

.contact-persons-wrapper {
  tui-input {
    background-color: #fff !important;
  }
}

tui-table-pagination {
  tui-hosted-dropdown {
    button {
      border: 2px solid var(--tui-primary) !important;
      padding: 0.3rem 1rem !important;
      margin: 0 0.2rem !important;
      border-radius: 1rem !important;
    }
  }
}

.product-order-form {
  tui-tabs {
    background-color: #ffff;
    box-shadow: none !important;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;

    button[tuiTab] {
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
    }

    button[tuiTab]:nth-child(even)._active {
      color: var(--tui-text-dark-01) !important;
    }

    ._active {
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
      background-color: #E6E6E6;
    }
  }

  .four-inputs {
    .tui-input--default {
      border-color: green !important;
    }

    tui-input-number {
      background-color: #ffff !important;
      box-shadow: none !important;
    }

    tui-hosted-dropdown {
      background-color: #ffff !important;
    }
  }
}

[_nghost-ng-c267318630] {
  margin: 0 !important;
}

.delivery-receipt-wrapper {
  .t-title {
    color: black !important;
  }
}

tui-accordion-item {
  tui-expand {
    background-color: #f8f8f8 !important;

    tui-textarea {
      background-color: #fff !important;
    }

    height: fit-content !important;
  }
}

.t-cell[data-state=hover]:hover {
  div {
    color: var(--tui-text-dark-02) !important;
  }
}


.role-wrapper {
  tui-input, tui-hosted-dropdown, tui-textarea {
    background-color: #fff !important;
  }

}

.bg-white {
  tui-hosted-dropdown {
    label {
      color: var(--tui-text-dark-03) !important;
    }
  }
}

.permission-card-title {
  button {
    div {
      padding: 0 !important;
    }
  }
}

.permission-title-wrapper {
  button {
    div {
      padding-left: 1rem !important;

      .t-content[_ngcontent-ng-c3926154569] {
        height: auto;
        margin-top: -0.15rem;
      }
    }

    div:hover {
      background: transparent !important;
    }
  }
}


#filter-tabs {
  box-shadow: none !important;
  color: var(--tui-text-01) !important;
}

tui-dialog-host {
  h2 {
    color: var(--tui-text-dark-01) !important;
  }
}

tui-notification {
  div {
    color: var(--tui-text-dark-01) !important;
  }
}

tui-file {
  color: var(--tui-text-dark-01) !important;
}

tui-pagination {
  button {
    background: var(--tui-text-dark-03) !important;
  }
}

#image-dialog {
  .t-content[_ngcontent-ng-c1591437670] {
    background: none !important;
  }
}

.delivery-receipt-link {
  color: var(--tui-text-03) !important;
}

.delivery-receipt-link:hover {
  color: var(--tui-text-01) !important;
}

.delivery-form-card {
  tui-input-number {
    background-color: #ffff !important;
  }

  tui-hosted-dropdown {
    background-color: #ffff !important;
  }

  #delete-card-button {
    .t-wrapper {
      padding: 0 !important;

      .t-left {
        margin-right: 0 !important;
      }
    }

    svg {
      color: var(--tui-text-dark-01) !important;
      opacity: 1 !important;
    }

    [tuiWrapper][data-appearance='icon'][data-appearance='icon'] {
      opacity: 1;
    }
  }
}

._hidden[_nghost-ng-c2294440466] input.t-input[_ngcontent-ng-c2294440466], .t-input tui-primitive-textfield._hidden[_nghost-ng-c2294440466], tui-primitive-textfield._hidden [_nghost-ng-c2294440466] {
  text-indent: 0 !important;
}

.t-strong[_ngcontent-ng-c2929971494] {
  color: var(--tui-primary) !important;
}


.t-content[_ngcontent-ng-c1591437670] {
  min-width: 35rem;
}

// progress background not transparent
#delivery-progress-wrapper {
  [_nghost-ng-c3023029366] {
    background: #{$primary_transparent};
  }
}

#filter-wrapper {
  .t-input[_ngcontent-ng-c2294440466] {
    color: white;
  }
}

[data-size=l]._focused[_nghost-ng-c2294440466]:not(._label-outside) .t-placeholder[_ngcontent-ng-c2294440466] {
  color: var(--tui-text-dark-01) !important;
}

#dashboard-year-overview {
  .t-labels-y_primary[_ngcontent-ng-c3613499106] {
    font-weight: bold !important;
    color: black !important;
  }
}

tui-dialog[data-appearance='transparent-dialog'] {
  .t-content[_ngcontent-ng-c1591437670] {
    background-color: transparent !important;
  }
}

// todo check if this has side effects
[tuiWrapper][data-appearance='whiteblock'] {
  color: black !important;
}
