// RESPONSIVE STYLING AND MEDIA QUERIES:
/** 1536px **/
//@media (min-width: 96rem) {
//
//}
//
///** 1280px **/
//@media (min-width: 80rem) {
//
//}
//
///** 1280px **/
//@media (min-width: 80rem) {
//
//}
//
///** 1024px **/
//@media (min-width: 64rem) {
//
//}
//
///** 768px **/
//@media (min-width: 48rem) {
//
//}
//
///** 640 **/
//@media (min-width: 40rem) {
//
//}
