// MIXINS are generic scss classes, which can be used globally inside this project, without importing

.bubble-gradient-top-left {
  width: 578px;
  height: 578px;
  top: 50%;
  transform: translateY(-50%);
  left: -195px;
  opacity: 0.32;
  z-index: -1;
  background: radial-gradient(circle at 50% 50%, #1FC47D 0%, #1FC47D 10%, rgba(238, 130, 238, 0) 100%);
  filter: blur(64px);
}

.bubble-gradient-top-right {
  width: 1078px;
  height: 1078px;
  top: -650px;
  right: -300px;
  opacity: 0.2;
  z-index: -1;
  background: radial-gradient(circle at 50% 50%, #1FC47D 0%, #1FC47D 10%, rgba(238, 130, 238, 0) 90%);
  filter: blur(64px);
}

.bubble-gradient-bottom {
  bottom: 100px;
  right: 0;
  transform: translate(0%, 50%);
  width: 1100px;
  height: 1100px;
  border-radius: 80%;
  z-index: -1;
  background: radial-gradient(circle at 50% 50%, #1FC47D 0%, #1FC47D 10%, rgba(238, 130, 238, 0) 75%);
  filter: blur(64px);
  opacity: 0.35;
}

.bubble-gradient-top-left, .bubble-gradient-top-right, .bubble-gradient-bottom {
  position: fixed;
}

// general input style
tui-input, tui-input-password {
  background-color: transparent !important;
}

.aroundPadding {
  padding: 2rem 2rem 2rem 0.5rem;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  min-width: fit-content;
  @extend .aroundPadding;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h1 {
    padding-top: 0;
    margin-right: 2.5rem;
  }
}


.min-height {
  height: min(85dvh, 100%);
}

.card {
  width: min(35rem, 90%);
  margin-inline: auto;
  padding: 2rem;
}

.same-row-inputs {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
  gap: 1rem;
}

.pt1 {
  padding-top: 1rem;
}

.pb1 {
  padding-bottom: 1rem;
}

.pb_05 {
  padding-bottom: 0.5rem;
}

.mt1 {
  margin-top: 1rem;
}

.mb1 {
  margin-bottom: 1rem;
}

.fx-grow1 {
  flex-grow: 1;
}

.button-wrapper {
  display: flex;
  gap: 1rem;

  & > * {
    flex-grow: 1;
  }
}

.dialog-title {
  font-size: 1.8rem;
  padding-top: 1rem;
  margin-top: -0.5rem;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.flex-col-g1 {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.scrollable-y {
  overflow-y: auto;
  height: 100%;
}

tui-dropdown {
  z-index: 9999 !important;
}

.detail-page-header {
  display: flex;
  align-items: center;

  justify-content: space-between;
  gap: 1rem;

  tui-svg {
    cursor: pointer;
    font-size: 1.5rem;
  }
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.deleteBtn {
  background-color: hsl(0, 73%, 77%) !important;
  color: hsl(0, 73%, 20%) !important;
}

// SET MAX WIDTH OF A CONTAINER
.no-overflow-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  width: 100%;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-align-center {
  display: flex;
  align-items: center;
  gap: var(--gap, 0.25rem);
  padding-bottom: 0.5rem;
}

.justify-end {
  justify-content: end;
}

.bg-white {
  padding: 2.5rem 2rem 1.5rem;
  background-color: #FFFFFF;
  border-radius: 1rem;
  color: #000000;
  min-width: max-content;

  .headline-wrapper {
    h2 {
      margin-top: 0;
      padding-top: 0;
    }
  }
}


.light-grey-bg {
  background-color: #F5F5F5;
  border-radius: 1rem;
  padding: 1rem;
}

.dialog-content {
  color: var(--tui-text-dark-01) !important;

  h3 {
    font-size: 1.1rem;
  }

  h1 {
    font-size: 1.5rem;
  }
}

.island-fit-content {
  width: fit-content;

  // add if the table should still have a min width
  min-width: 65rem;
}

.search-form {
  min-width: 17rem;

  .t-svg[_ngcontent-ng-c3944244699] {
    color: white !important;
  }
}

.scroll {
  scrollbar-width: none !important;
  -ms-overflow-style: none !important;

  &::-webkit-scrollbar,
  &::-webkit-scrollbar-thumb {
    display: none !important;
  }

  min-height: 64px;
  max-height: 200px;
}

.half-transparent {
  background-color: rgba(34, 44, 40, 0.5) !important;
}

.pointer {
  cursor: pointer;
}

.light-divider {
  margin-bottom: 1rem;
  margin-top: 0.5rem;
  background-color: #dcdcdc;
  border: none;
  height: 1px
}

.full-grid-width {
  grid-column: 1 / -1 !important;
}

.border-left {
  border-left: 3px solid var(--tui-secondary-active);
  margin-left: 1rem;
  padding-left: 1rem;
}


.permission-container {
  padding: 1.5rem;
  background-color: var(--tui-warn-bg);
  color: var(--tui-warn-txt);
  border-radius: 1rem;
}
